import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"
 
const About = ({
  data: {
    about: {nodes},
  },
}) => {
  const{title, stack, content, image} = nodes[0]

  return (
    <Layout>
      <SEO title="About Me" description="A little description page about Callum Whitehouse"/>
      <section className="about-page">
        <div className="section-center about-center">
          <Image fluid={image.childImageSharp.fluid} className="about-img"/>
          <article className="about-text">
            <Title title={title} />
            <ReactMarkdown source={content} />
            <div className="about-stack">
              {stack.map((item)=>{
                return (
                  <span key={item.id}>{item.title}</span>
                )
              })}
            </div>
          </article>
        </div>
      </section>
    </Layout>
    
  )
}

export const query = graphql`
{
  about:allStrapiAbout {
    nodes {
      title
      content
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stack {
        title
        id
      }
    }
  }
}
`
export default About
